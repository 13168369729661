dmx.getModboxOptions = (type, options) => {
  const newOptions = {
    backdrop: 'static'
  };

  newOptions.body = options.message;

  if (options.title) {
    newOptions.title = options.title;
  }

  if (options.size) {
    newOptions.size = options.size;
  }

  if (options.backdrop != null) {
    if (options.backdrop === true || options.backdrop === 'true') {
      newOptions.backdrop = true;
    } else if (options.backdrop === false || options.backdrop === 'false') {
      newOptions.backdrop = false;
    }
  }

  if (options.animate === false) {
    newOptions.fade = false;
  }

  if (options.closeButton === false) {
    newOptions.showHeaderClose = false;
  }

  if (options.swapButtonOrder) {
    newOptions.swapButtonOrder = true;
  }

  if (options.centerVertical) {
    newOptions.center = true;
  }

  if (options.scrollable !== true) {
    newOptions.scrollable = false;
  }

  if (type == 'alert') {
    if (options.buttons && options.buttons.ok) {
      newOptions.closeButton = {};

      if (options.buttons.ok.label) {
        newOptions.closeButton.label = options.buttons.ok.label
      }

      if (options.buttons.ok.className) {
        newOptions.closeButton.style = options.buttons.ok.className.replace('btn-', '');
      }
    }
  } else if (type == 'confirm') {
    if (options.buttons && options.buttons.confirm) {
      newOptions.okButton = {};

      if (options.buttons.confirm.label) {
        newOptions.okButton.label = options.buttons.confirm.label;
      }

      if (options.buttons.confirm.className) {
        newOptions.okButton.style = options.buttons.confirm.className.replace('btn-', '');
      }
    }

    if (options.buttons && options.buttons.cancel) {
      newOptions.closeButton = {};

      if (options.buttons.cancel.label) {
        newOptions.closeButton.label = options.buttons.cancel.label;
      }

      if (options.buttons.cancel.className) {
        newOptions.closeButton.style = options.buttons.cancel.className.replace('btn-', '');
      }
    }
  } else if (type == 'prompt') {
    if (options.buttons && options.buttons.confirm) {
      newOptions.okButton = {};

      if (options.buttons.confirm.label) {
        newOptions.okButton.label = options.buttons.confirm.label;
      }

      if (options.buttons.confirm.className) {
        newOptions.okButton.style = options.buttons.confirm.className.replace('btn-', '');
      }
    }

    if (options.buttons && options.buttons.cancel) {
      newOptions.closeButton = {};

      if (options.buttons.cancel.label) {
        newOptions.closeButton.label = options.buttons.cancel.label;
      }

      if (options.buttons.cancel.className) {
        newOptions.closeButton.style = options.buttons.cancel.className.replace('btn-', '');
      }
    }

    if (options.inputType == 'select') {
      newOptions.input =
        `<select
          class="form-select"
          ${options.multiple ? 'multiple' : ''}
          ${options.required ? 'required' : ''}
        >
          ${options.inputOptions.map((option) => `
            <option
              value="${option.value || ''}"
              ${options.value == option.value ? 'selected' : ''}
            >${option.text || option.value}</option>
          `).join('')}
        </select>`;
    } else if (options.inputType == 'checkbox' || options.inputType == 'radio') {
      newOptions.input =
        options.inputOptions.map((option, index) =>
          `<div class="form-check">
            <input
              id="modbox-input-${index}"
              type="${options.inputType}"
              name="modbox-input-name"
              value="${option.value || ''}"
              ${options.value == option.value ? 'checked' : ''}
            >
            <label class="form-check-label" for="modbox-input-${index}">
              ${option.text || option.value}
            </label>
          </div>`
        ).join('');
    } else if (options.inputType == 'textarea') {
      newOptions.input =
        `<textarea
          class="form-control"
          ${options.rows ? `rows="${options.rows}"` : ''}
          ${options.placeholder ? `placeholder="${options.placeholder}"` : ''}
          ${options.autocomplete ? `autocomplete="${options.autocomplete}"` : ''}
          ${options.minlength ? `minlength="${options.minlength}"` : ''}
          ${options.maxlength ? `maxlength="${options.maxlength}"` : ''}
          ${options.required ? 'required' : ''}
        >${options.value || ''}</textarea>`;
    } else {
      newOptions.input =
        `<input
          type="${options.inputType}"
          class="${options.inputType == 'range' ? 'form-range' : 'form-control'}"
          value="${options.value || ''}"
          ${options.placeholder ? `placeholder="${options.placeholder}"` : ''}
          ${options.autocomplete ? `autocomplete="${options.autocomplete}"` : ''}
          ${options.minlength ? `minlength="${options.minlength}"` : ''}
          ${options.maxlength ? `maxlength="${options.maxlength}"` : ''}
          ${options.min ? `min="${options.min}"` : ''}
          ${options.max ? `max="${options.max}"` : ''}
          ${options.step ? `step="${options.step}"` : ''}
          ${options.pattern ? `pattern="${options.pattern}"` : ''}
          ${options.required ? 'required' : ''}
        >`;
    }

    newOptions.body = `
      ${options.message ? `<p>${options.message}</p>` : ''}
      ${newOptions.input}
    `.trim();
  }

  return newOptions;
}

dmx.Actions({

  'bootbox.alert': function(options) {
    return modbox.alert(dmx.getModboxOptions('alert', this.parse(options)));
  },

  'bootbox.info': function(options) {
    return modbox.info(dmx.getModboxOptions('alert', this.parse(options)));
  },

  'bootbox.success': function(options) {
    return modbox.success(dmx.getModboxOptions('alert', this.parse(options)));
  },

  'bootbox.warning': function(options) {
    return modbox.warning(dmx.getModboxOptions('alert', this.parse(options)));
  },

  'bootbox.danger': function(options) {
    return modbox.danger(dmx.getModboxOptions('alert', this.parse(options)));
  },

  'bootbox.confirm': function(options) {
    options = Object.assign({}, options);

    const condition = {
        'then': options.then,
        'else': options.else
    };

    delete options.then;
    delete options.else;
    
    return modbox.confirm(dmx.getModboxOptions('confirm', this.parse(options))).then(() => {
      if (condition.then) {
        return this._exec(condition.then).then(() => true);
      }
      return true;
    }).catch(() => {
      if (condition.else) {
        return this._exec(condition.else).then(() => false);
      }
      return false;
    });
  },

  'bootbox.prompt': function(_options) {
    const options = dmx.extend(true, {
      okButton: {
        label: 'OK',
        style: 'primary'
      },
      closeButton: {
        label: 'Close',
        style: 'secondary'
      }
    }, dmx.getModboxOptions('prompt', this.parse(_options)), {
      destroyOnClose: true,
      defaultButton: false,
      buttons: []
    });

    return new Promise(resolve => {
      const box = new modbox(options);
      const inputEl = box.modalEl.querySelector('input,select,textarea');

      if (inputEl.type == 'radio') {
        inputEl.checked = true;
      }

      options.okButton.close = false;
      options.okButton.callback = () => {
        const isValid = inputEl ? inputEl.reportValidity() : true;

        if (isValid) {
          if (inputEl.nodeName == 'SELECT' && inputEl.multiple) {
            resolve(Array.from(inputEl.selectedOptions).map(option => option.value));
          } else if (inputEl.type == 'checkbox') {
            const checkedEl = box.modalEl.querySelectorAll('input:checked');
            resolve(Array.from(checkedEl).map(el => el.value));
          } else if (inputEl.type == 'radio') {
            const checkedEl = box.modalEl.querySelector('input:checked');
            resolve(checkedEl.value);
          } else {
            resolve(inputEl.value);
          }

          box.hide();
        }
      };

      const okBtn = box.addButton(options.okButton);
      const closeBtn = box.addButton(options.closeButton);

      if (inputEl && inputEl.nodeName == 'INPUT') {
        inputEl.addEventListener('keyup', (event) => {
          if (event.key === 'Enter') {
            okBtn.click();
          }
        })
      }

      box.addEvent('hide', () => {
        resolve(null);
      });

      box.show();
    });
  }

});
