dmx.Component('bootbox', {

  methods: {
    alert: function(options) {
      return modbox.alert(dmx.getModboxOptions('alert', options));
    },

    info: function(options) {
      return modbox.info(dmx.getModboxOptions('alert', options));
    },
  
    success: function(options) {
      return modbox.success(dmx.getModboxOptions('alert', options));
    },
  
    warning: function(options) {
      return modbox.warning(dmx.getModboxOptions('alert', options));
    },
  
    danger: function(options) {
      return modbox.danger(dmx.getModboxOptions('alert', options));
    },
  }

});